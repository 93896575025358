.address ul li{
	list-style: none;
}

#mainmenu .nav-item.active .nav-link {
    text-decoration: underline !important;  
}
.langnav li{
	list-style: none;
	float: left;
}
.langnav .nav-link{
	padding: 0px !important
}
table{
	border-color: transparent;
}

html,
body {
    font-family: 'Poppins', sans-serif;
    font-weight:normal;
    overflow-x:hidden;
    -webkit-font-smoothing: antialiased;
    color: #2d3038;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #2d3038;
    margin-top: 10px;
    margin-bottom: 10px;    
}
.h1 {
    font-size: 2.5rem;
    font-weight: bold;
    @media (max-width: 479px) {
	    font-size: 1.5rem;
	    }
    
}
@media (max-width: 479px) {
	.slick-prev, .slick-next{
		display: none !important;
	}    
 
 }  
    
p img{
	max-width: 100%;
}


a{
    color: #2d3038;
}

a:hover {
    color: #00aeef;
}

a, a:hover, a:active,
a:focus, button:focus, .btn:focus,
.form-control:focus {
    outline: none !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

.section-padding {
    padding-top: 80px;
    padding-bottom: 100px;
}

.section-title {
    margin-bottom: 60px;
}

.section-title h1 {
    font-size: 56px;
}

.home-two .section-title h1 {
    font-size: 50px;
    margin-top: 0;
}

.bg-color {
    background-color: #f9f9f9;
    position: relative;
}

.bg-color:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    display: block;
}

.section-content {
    max-width: 750px;
}

.btn.btn-primary {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #3c70b4;
    border: 1px solid #3c70b4;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.btn.btn-primary:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}

.btn.btn-primary:hover:before {
    left: 0;
}

.btn.btn-primary:hover {
    color: #3c70b4;
}



/* ==========================================================================
    Home Banner
 ========================================================================== */

/* Navigation */

#navigation.menu-one .navbar {
    width: 30%;
    left: inherit;
    right: 0;
    padding: 0;
}

#navigation .navbar-nav>li {
    background-color: #2d3038;
}

#navigation.menu-one .navbar-nav>li {
    float: none;

    position: relative;
    margin-left: 10px;    
}

#navigation.menu-one .navbar-nav {
    float: none;
    flex: inherit;
}

#navigation.menu-one .navbar-nav> li {
    width: 100%;
}

#navigation.menu-one .navbar-nav> li:hover,
#navigation.menu-one .navbar-nav> li.current {
    margin-left: 0;
    padding-left: 10px;
}
 
#navigation.menu-one .navbar-nav> li:last-child:hover,
#navigation.menu-one .navbar-nav> li:last-child.current {
    margin-left: 10px;
    padding-left: 0;
}

#navigation .navbar-nav>li a {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    height: 100%;
    width: 100%;
    padding: 0;
}

#navigation.menu-one .navbar-nav>li span {
    position: absolute;
    top: 50%;
    left: 90px;
    margin-top: -8px;
}

#navigation .navbar-nav>li i {
    position: absolute;
    left: -50px;
    font-size: 30px;
    margin-top: -6px;
}

.nav>li>a:focus, 
.nav>li>a:hover {
    background-color: transparent;
}

#navigation.menu-one .navbar:before {
    left: 15px;
    right: -100%;
} 

#navigation.menu-one .navbar:after {
   display: none;
}

#navigation.menu-one .navbar-header {
    position: absolute;
    top: 0;
}

.home-one .logo {
    height: 65px;
    float: none;
    margin-left: 10px;
    padding: 4px 30px;
}

.home-one .navbar-nav {
    margin-top: 65px;
}

#navigation .navbar-nav>li:nth-child(1) {
    background-color: #f4bf00;
}

#navigation .navbar-nav>li:nth-child(2) {
    background-color: #f7941d;
}

#navigation .navbar-nav>li:nth-child(3) {
    background-color: #f26522;
}

#navigation .navbar-nav>li:nth-child(4) {
    background-color: #ed1c24;
}

#navigation .navbar-nav>li:nth-child(5) {
    background-color: #e10337;
}

#navigation .navbar-nav>li:nth-child(6) {
    background-color: #b90162;
}

#navigation .navbar-nav>li:nth-child(7) {
    background-color: #873e95;
}

#navigation .navbar-nav>li:nth-child(8) {
    background-color: #3c70b4;
}

#navigation .navbar-nav>li:nth-child(9) {
    background-color: #05ac80;
}

#navigation .navbar-nav>li:last-child {
    background-color: #87c05e;
}

#navigation .navbar-nav>li .social {
    margin-left: 25px;
    overflow: hidden;
}

#navigation .navbar-nav>li .social li {
   float: left;
}

#navigation .navbar-nav>li .social li a {
    padding: 20px 15px;
}

#navigation .navbar-nav>li ul li {
    padding: 0;
}

#navigation .navbar-nav>li ul li i {
    font-size: 16px;
    margin-right: 0;
    position: inherit;
}

#navigation .navbar-nav>li ul li a {
    padding: 10px;
}

#navigation .navbar-nav>li ul li a:hover i {
    font-size: 16px;
    color: #000;
}

/* home-two */

#navigation .navbar {
    border: 0;
    padding: 0;
}

#navigation.menu-two .navbar-nav>li>a {
    padding: 28px 18px;
    display: block;
    position: inherit !important;
}

#navigation.menu-two .navbar-nav>li.current a,
#navigation.menu-two .navbar-nav>li a:hover {
    padding-bottom: 40px;
}

#navigation.menu-two .navbar-nav>li:last-child {
    background-color: #3c70b4;
}

#navigation.menu-two .navbar {
    display: block;
}

#navigation.menu-two .navbar-header {
    float: left;
}

#navigation.menu-two .navbar-nav>li {
    float: left;
}

#navigation.menu-two .navbar-nav {
    display: block;
}


#navigation .navbar:before,
#navigation .navbar:after {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 76px;
    background-color: #2d3038;
    z-index: -1;
}

#navigation .navbar:before {
    right:0;
    background-color: #2d3038;
    left: -100%;
}

#navigation .navbar:after {
    left: 50%;
    background-color: #3c70b4;
    right: 0;
    z-index: -1;
}

.logo {
    background-color: #2d3038;
    height: 76px;
    padding: 5px 25px 10px 15px;
    margin-right: 0;
}

.menu-two .fixed-top {
    max-width: 1170px;
    margin: 0 auto;
}


/*Dropdown CSS*/

ul.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    padding:7px 0;
    margin: 0;
    min-width: 160px; 
    display: none;
    z-index: 999;
    background-color: #f4bf00;
}
  
.nav.navbar-nav > li:hover > ul.sub-menu {
    display: block;
    -webkit-animation: fadeInUp 500ms;
    -moz-animation: fadeInUp 500ms;
    -ms-animation: fadeInUp 500ms;
    -o-animation: fadeInUp 500ms;
    animation: fadeInUp 500ms;
}

#navigation.menu-two .navbar-nav .sub-menu li a {
    padding: 5px 18px;
    color: #fff;
    font-size: 13px;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*End Drpdown Menu CSS*/

/* Navbar Toggle */

.navbar-toggle {
    border-color: #fff;
    background-color: transparent;
    padding: 12px 10px;
}

.navbar-toggle .icon-bar {
    background-color: #fff;
}

.home-banner-one {
    background-color: #fff;
}

.home-banner-one .banner-content {
    background-image: url(../images/bg/banner.jpg);
    background-color: #e7e7e7;
    position: relative;
    background-position: center;
    background-size: cover;
}

.home-two #home-banner {
    background-image: url(../img/haircolorkitchen-sonya-bloemhard-im1.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}
.trainingen{
	background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.about{
	background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.particulieren{
	background-repeat: no-repeat;
    background-position: right;
    background-size: contain;	
}

.banner-info h1 {
    font-size: 60px;
    font-family: 'Poppins';
background: rgb(163,109,40);
background: linear-gradient(0deg, rgba(163,109,40,1) 17%, rgba(247,229,124,1) 49%, rgba(163,109,40,1) 83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px #333);
 
}
.banner-info h1:after {
  background: none;
  content: attr(data-text);
  left: 0;
  top: 0;
  z-index: -1;
  position: absolute;
  text-shadow: 2px 2px 0px #000;
}

.banner-info h2 {
    font-size: 40px;
    margin-top: 0;
    font-family: 'Sacramento';
    font-weight: normal;
}

.banner-info h3 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 26px;
}

/* Home Two  */

.home-two .banner-info h1 {
    font-size: 80px;
    margin-bottom: 0;
    font-family: 'Poppins';
    background: rgb(163,109,40);
background: linear-gradient(0deg, rgba(163,109,40,1) 17%, rgba(247,229,124,1) 49%, rgba(163,109,40,1) 83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px #333);
}

.banner-content, #home-banner {
    position: relative;
    width: 100%;
    height: 700px;
    @media (max-width: 479px) {
	    height: 269px !important;
	    }
    
}

.banner-content .banner-info {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    margin-top: -100px
}

.home-two .home-social {
    position: absolute;
    top: 40px;
}

.menu-two .home-social li+li {
    margin-left: 15px;
}

.menu-two .home-social li a {
    color: #2d3038;
    font-size: 16px;
}

.menu-two .home-social li a:hover {
    color: #00aeef;
}


/* About Section */

.about-section .section-title {
    margin-bottom: 45px;
}

.about-section .section-title h4 {
    margin-top: 0;
    line-height: 0;
} 

.about-info {
    font-size: 14px;
    line-height: 24px;
}

.about-info .signature h1 {
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Sacramento', cursive;    
}

.about-section address {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 40px;
}

.about-section address span {
    font-weight: 700;
}

.achievement li {
    float: left;
}

.achievement li+li {
    margin-left: 30px;
}

.achievement-info .counter {
    font-size: 56px;
    font-weight: 700;
    color: #f7941d;
    line-height: 62px;
}

.achievement-info .counter1 {
    color: #873e95;
}

.achievement-info .counter2 {
    color: #3c70b4;
}

.achievement-info .counter3 {
    color: #87c05e;
}

.achievement-info h4 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 0;
}

/* Skill Section */

.skill-section .rating-bar {
    width: 360px;
}

.progress-content {
    overflow: hidden;
}

.bar-left {
    float: left;
}

.bar-right {
    float: right;
}

.rating-bar label,
.rating-bar span {
    font-size: 16px;
    margin-bottom: 10px;
}

.skill-progress {
    position: relative;
    margin-bottom: 40px;
}

.progress {
    height: 8px;
    margin-bottom: 22px;
    background-color: #ebebeb;
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
    position: relative;
}

.progress-bar {
    display: block;
    flex: inherit;
   background-color: #f4bf00;
   box-shadow: none;
  -webkit-transition: width 2s ease-in-out;
  -moz-transition: width 2s ease-in-out;
  -ms-transition: width 2s ease-in-out;
  -o-transition: width 2s ease-in-out;
  transition:  width 2s ease-in-out;
  border-radius: 4px;   
}

.progress-bar.bar1 {
    background-color: #b90162;
}

.progress-bar.bar2 {
    background-color: #3c70b4;
}

.progress-bar.bar3 {
    background-color: #f26522;
}

.progress-bar.bar4 {
    background-color: #ed1c24;
}

.progress-bar.bar5 {
    background-color: #87c05e;
}

.language-skill {
    margin-top: 50px;
}

.language-skill canvas {
    display: none;
}

.language-skill li {
    float: left;
}

.language-skill li+li{
    margin-left: 80px;
}

.language-skill .percent {
    display: inline-block;
    text-align: center;
    width: 116px;
    height: 116px;
    line-height: 116px;
    background-color: #f7941d;
    border-radius: 50%;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
}

.language-skill .percent1 {
    background-color: #f26522;
}

.language-skill .percent2 {
    background-color: #3c70b4;
}

.language-skill .percent3 {
    background-color: #87c05e;
}

.language-skill .percent:after {
    content: '%';
}

.language-skill .chart h5 {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;
}

.text-info {
    font-size: 14px;
    line-height: 24px;
    color: #2d3038 !important;
    margin-bottom: 50px;
}


/* Exprience Section */

.exprience-section .exprience {
    overflow: hidden;
}

.exprience-image {
    float: left;
    margin-right: 20px;
    width: 175px;
    height: 160px;
    overflow: hidden;
}

.exprience-section .exprience img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

.exprience-section .exprience:hover img  {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);    
}

.exprience-info {
    font-size: 14px;
    line-height: 24px;
}

.exprience-info h3 {
    margin-bottom: 0;
    line-height: 28px;
    margin-top: 15px;
}

.exprience-info h5 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 20px;
}


/* Education Section */

.education-info {
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 5px;
    padding-top: 10px;
}

.education-info h3 {
    margin-top: 30px;
}

.education-info h5,
.education-info h6 {
    font-size: 16px;
    line-height: 12px;
}

.education-info h6 {
    margin-bottom: 20px;
}

/* Portfolio Section */


.portfolio-item {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}

.portfolio-overlay {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ef6e30;
    color: #fff;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -ms-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;    
}

.portfolio-item:hover .portfolio-overlay {
    top: 0;
}

.portfolio-info {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;
    font-weight: normal;
    padding-left: 30px;
    padding-bottom: 20px;
}

.portfolio-info h3 {
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
}

.portfolio-info a {
    font-size: 30px;
    color: #fff;
}

.portfolio-info a:hover {
    color: #bb460d;
}

/*Magnific-popup overwrite CSS*/

.mfp-figure {
    background-color: #fff;
}

img.mfp-img {
    padding:12px;
}

.mfp-image-holder .mfp-close {
    top:-35px;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor:pointer;
}

.mfp-counter {
    right: 50%;
    color: #fff;
    top: 50px;
    margin-right: -20px;
}


/* Contact Section */

.contact-section .icons {
    float: left;
    font-size: 36px;
    margin-right: 30px;
}

.home-two .contact-section .section-title h1 {
    margin-top: 20px;
}

.contact-section li {
    border-bottom: 1px solid #f1f1f1;
    padding: 30px 0;
}

.contact-section li:first-child {
    padding-top: 25px;
}

.contact-section h3 {
    margin-top: 5px;
}

.contact-section li,
.contact-section li a {
    font-size: 16px;
    color: #2d3038;
}

.contact-section li a:hover {
    color: #87c05e;
}

.contact-section li:hover .icons,
.contact-info:hover .icons {
    -webkit-transform:rotate(360deg) ;
    -moz-transform:rotate(360deg) ;
    -ms-transform:rotate(360deg) ;
    -o-transform:rotate(360deg) ;
    transform:rotate(360deg) ;
}

.contact-section .icons,
.contact-info .icons {
    -webkit-transition: all 800ms;
    -moz-transition: all 800ms;
    -ms-transition: all 800ms;
    -o-transition: all 800ms;
    transition: all 800ms;
}

.contact-section .icons {
    color: #f7941d;
}

.contact-section .icons1 {
    color: #ed1c24;
}

.contact-section .icons2{
    color: #b90162;
}

.contact-section .icons3 {
    color: #3c70b4;
}

.contact-section .social li {
    border: none;
    padding: 0;
    display: inline-block;
}

.contact-section .social li+li {
    margin-left: 10px;
}

.contact-section .social a {
    color: #2d3038;
}

.contact-section .social i.fa-facebook:hover {
    color: #4060A5;
}

.contact-section .social i.fa-twitter:hover {
    color: #00ABE3;
}

.contact-section .social i.fa-google-plus:hover {
    color: #E64522;
}

.contact-section .social i.fa-dribbble:hover {
    color: #EA4C89;
}

.contact-section .social i.fa-behance:hover {
    color: #2E99D1;
}

.contact-section .social i.fa-github-alt:hover {
    color: #417DB9;
}

.contact {
    font-size: 16px;
    line-height: 24px;
    padding-left: 50px;
    margin-top: 45px;
}

.contact .contact-info {
    margin-bottom: 15px;
}

.contact .title {
    position: relative;
}

.contact .icons {
    position: absolute;
    left: -50px;
    top: 0;
    font-size: 36px;
}

.contact .icons i {
    color: #87c05e;
}

.contact .form-group {
    margin-top: 20px;
    margin-bottom: 0;
}

.contact .form-control {
    height: 45px;
    line-height: 45px;
    border: none;
    box-shadow: none;
    color: #2d3038;
    font-size: 14px;
    background-color: #f2f2f2;
    padding-left: 20px;
    padding-right: 25px;
    border: 1px solid #f2f2f2;
}

.contact .form-control:focus {
    border-color: #87c05e;
}

.contact textarea.form-control {
    resize: none;
    height: 125px;
}

.contact .btn.btn-primary {
    margin-top: 20px;
}

.contact .form-control::-webkit-input-placeholder {
   color: #2d3038;
}

.contact .form-control:-moz-placeholder {
   color: #2d3038;  
}

.contact .form-control::-moz-placeholder { 
   color: #2d3038;  
}

.contact .form-control:-ms-input-placeholder {  
   color: #2d3038;  
}

/* Blog Section */

.blog-section .entry-post {
    margin-bottom: 30px;
    overflow: hidden;
}

.entry-thumbnail {
    position: relative;
    z-index: -1;
    overflow: hidden;
}

.thumbnail-oberlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(45, 48, 56, 0.3); 
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.entry-post:hover .thumbnail-oberlay {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    border-radius: 0%;
}

.entry-post .time {
    margin-top: -45px;
    z-index: 1;
}

.entry-post .time a {
    width: 84px;
    height: 84px;
    color: #fff;
    font-size: 36px;    
    background-color: #f4bf00;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: all 900ms;
    -moz-transition: all 900ms;
    -ms-transition: all 900ms;
    -o-transition: all 900ms;
    transition: all 900ms;
}

.blog-section .entry-post:hover .time a {
    border-radius: 0%;
}

.post-content .time.time1 a {
    background-color: #f26522;
}

.post-content .time.time2 a {
    background-color: #ed1c24;
}

.post-content .time.time3 a {
    background-color: #b90162;
}

.post-content .time.time4 a {
    background-color: #873e95;
}

.post-content .time.time5 a {
    background-color: #87c05e;
}

.entry-post .time span {
    display: block;
    font-size: 16px;
    line-height: 0;
    margin-top: -2px;
}

.post-content {
    border: 1px solid #f0f0f0;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 30px;
}

.post-content h2 {
    line-height: 21px;
}

.post-content h2 a  {
    font-size: 18px;
    color: #2d3038;
    display: block;
}

.post-content h2 a:hover {
    color: #87c05e;
}


/* Profile Section */

.profile-section .section-padding {
    padding-top: 110px;
    padding-bottom: 120px;
}

.profile-section {
    font-size: 14px;
    line-height: 24px;  
}

.profile-section .profile-info {
    margin-bottom: 70px;
    overflow: hidden;
}

.profile-logo {
    float: left;
    margin-right: 40px;
}

.profile-info h1 {
    font-size: 56px;
    margin: 0;
}

.profile-info h4 {
    margin-top: 5px;
}

.profile-info address {
    font-size: 14px;
    line-height: 24px;
}

.profile-info address a {
    color: #2d3038;
}

.profile-info address a:hover {
    color: #87c05e;
}

.profile-section h3 {
    margin-top: 25px;
    margin-bottom: 25px;
    display: inline-block;
}

.career-objective {
    padding-bottom: 10px;
}

.sub-content {
    padding-left: 70px;
}

.history-content .title {
    position: relative;
}

.profile-section .icons {
    display: inline;
    color: #f4bf00;
    margin-right: 33px;
    font-size: 30px;
}

.work-history .icons {
    color: #f7941d;
}

.educational-background h3,
.work-history h3 {
    margin-bottom: 20px;
}

.work-history hr {
    margin-bottom: 30px;
}

.work-history .history h5,
.work-history .history h6 {
    font-size: 14px;
}

.work-history .history h5 {
    font-weight: 700;
}

.work-history .history h6 {
    font-weight: normal;
    margin-bottom: 25px;
}

.educational-background {
    margin-bottom: 25px;
}

.educational-background .icons {
    color: #ed1c24;
    margin-right: 22px;
}

.educational-background h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.educational-background hr {
    margin: 25px 0;
}

.language-proficiency {
    padding-bottom: 35px;
}

.language-proficiency .icons {
    color: #b90162;
}

.language-proficiency h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
}

.language-proficiency ul>li {
   display: inline-block;
}

.language-proficiency ul>li+li {
    margin-left: 20px;
    padding-left: 30px;
    border-left: 1px solid #f1f1f1;
}

.language-proficiency ul li ul li {
    float: left;
    color: #bbb;
}

.language-proficiency ul li ul li+li {
    margin-left: 5px;
    border-left: 0;
    padding-left: 0;
}

.expertise {
    padding-bottom: 10px;
}

.expertise .icons {
    color: #873e95;
}

.expertise .rating-bar label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.expertise .progress-bar {
    background-color: #873e95;
}

.expertise .skill-progress {
    margin-bottom: 35px;
}

.personal-info,
.portfolio {
    padding-bottom: 25px;
}

.personal-info .icons {
    color: #3c70b4;
}

.personal-info .address li,
.portfolio li {
    overflow: hidden;
    line-height: 30px;
}

.personal-info .address li h5,
.portfolio li h5 {
    float: left;
    margin-top: 5px;
    min-width: 180px;
}

.personal-info .address li span,
.portfolio li span {
    margin-right: 30px;
}

.portfolio .icons {
    color: #05ac80;
    margin-right: 28px;
}

.portfolio li a {
    color: #2d3038;
}

.portfolio li a:hover {
    color: #87c05e;
}

.declaration h1 {
    font-weight: normal;
    margin-top: 45px;
    font-family: 'Sacramento', cursive;
}

.declaration .icons {
    color: #87c05e;
}

.profile-section .btn.btn-primary {
    margin-top: 65px;
}

/* Footer Section */

#footer {
    color: #fff;
    background-color: #2d3038;
    padding-top: 22px;
    padding-bottom: 10px;
}

#footer a {
    color: #fff;
}

#footer a:hover {
    color: #6bff00;
}

/* Main Blog */
.widget {
    margin-bottom: 30px;
    overflow: hidden;
}
.widget img {
    max-width: 100%;
}
.widget.widget_search form {
    position: relative;
}
.widget_search button[type=submit] {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border-radius: 0;
    background-color:#2d3038;
    color: #fff;
}

.form-control {
    height: 48px;
    box-shadow: none;
    -webkit-box-shadow:none;
}

.form-control:focus, input[type="search"]:focus{
    border: 1px solid #2d3038;
}
h3.widget_title {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom:1px solid #f0f0f0;
}

.widget ul {

}

.widget ul li {
    padding:7px 0;
    margin:7px 0;
    position: relative;
}

.widget ul li a {
    padding-left: 10px;
}

.widget ul li:before {
    content: "\f178";
    font-family: FontAwesome;
}

.widget> ul> li:first-child {
    border-top: 0;
}

.widget ul ul {
    padding-top: 5px;
}

.widget ul ul li{
    padding-left: 15px;
}

/* Blog Details */


#blog-details .section-title {
    margin-bottom: 40px;
}

#blog-details .post-content {
    border: none;
    padding: 0;
    margin-left: 110px;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 35px;
}

#blog-details .entry-thumbnail {
    position: inherit;
}

#blog-details .entry-post .time {
    margin-top: -15px;
    float: left;
}

#blog-details .post-content h2 {
    font-size: 30px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 20px;
}

.entry-summary {
    border: 1px solid #f0f0f0;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 55px;
    padding-bottom: 35px;
    line-height: 24px;
}

.entry-summary p {
    margin-bottom: 20px;
}

.blog-social {
    margin: 40px 0;
}

.post-action {
    border: 1px solid #f0f0f0;
    overflow: hidden;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 25px;
    margin-bottom: 75px;
}

.post-action li {
    max-width: 225px;
}

.post-action .previous-post {
    float: left;
}

.post-action .next-post {
    float: right;
    text-align: right;
}

.post-action span {
    font-size: 14px;    
    color: #cfcfcf;
    text-transform: uppercase;
}

.post-action h3 {
    line-height: 19px;
    margin-top: 5px;
}

.post-action h3 a {
    font-size: 16px;
    color: #2d3038;
}

.post-action h3 a:hover {
    color: #87c05e;
}

/* ==========================================================================
    Extra Css
 ========================================================================== */

a, #contact-section li a i:hover,
.thumbnail-oberlay, .icons,
.exprience-image img,
.btn.btn-primary:before,
.btn.btn-primary,
.contact-section .social i,
.navbar-nav>li, .navbar-nav>li ul li i,
.navbar-nav > li span {
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -ms-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
}


@font-face {
    font-family: 'rookies-showtimesregular';
    src: url('../fonts/rookies_showtimes-webfont.woff2') format('woff2'),
         url('../fonts/rookies_showtimes-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

} 

/* lg */ 
@media (min-width: 1400px) {
	
}

/* md */
@media (min-width: 992px) and (max-width: 1199px) {


	#navigation.menu-one .navbar {
		width: 27%;
	}
	.achievement li + li {
		margin-left: 20px;
	}
	.skill-section .rating-bar {
		width: 47%;
	}
	.progress-content,
	.expertise .sub-content {
		padding-right: 20px;
	}
	.language-skill li + li {
		margin-left: 45px;
	}
	#navigation.menu-two .navbar-nav > li a {
		padding: 23px 10px;
		font-size: 12px;
	}
	#navigation.menu-two .navbar-nav > li.current a, 
	#navigation.menu-two .navbar-nav > li a:hover {
		padding-bottom: 30px;
	}
	.home-two .banner-info h1 {
		font-size: 60px;
	}	
	#navigation .navbar:before, #navigation .navbar:after {
		height: 64px;
	}
	.logo {
	    height: 64px;
	    padding: 5px 15px;
	    img{
		    height:55px;
	    }
	}	
	#navigation .navbar-nav > li .social li a {
		padding: 20px 10px;
	}
}

@media (max-width: 991px) {
  .navbar-header {
      float: none;
  }
  .navbar-collapse {
  	margin-top: 65px;
  }
  .navbar-toggler {
  	position: absolute;
  	top:20px;
  	right: 30px;
  	color: #fff;
  	padding: 6px 8px;
  	line-height: 0;
  	border: 1px solid #fff;
  }
  .home-one .logo {
  	padding: 9px 30px;
  	margin-left: 0;
  }
  .navbar-toggler-icon {
  	width: inherit;
  	height: inherit;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
      margin-top: 17px;
  }
  .navbar-nav {
      float: none!important;
  }
  .navbar-nav>li {
      float: none;
  }
  .collapse.in{
      display:block !important;
  }
  .home-two .home-social {
		top: 100px;
	}
	.banner-content .banner-info {
		top: 100%;
		margin-top: -220px;
	}
	#navigation .navbar:before {
		width: 200%;
	}
	#navigation.menu-two .navbar {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
	}
	#navigation.menu-two .navbar-nav > li {
		float: none;
	}
	#navigation.menu-two .navbar-collapse {
		margin-top: 0;
	}
	#blog-details,
	.blog-section {
		margin-top: 100px;
	}

}

/* sm */
@media (min-width: 768px) and (max-width: 991px) {
	.navbar-toggle  {
	 	margin-top: 15px;
	}
	#navigation.menu-one .navbar-nav > li:first-child a {
		margin-left: 20px;
	}
	#navigation.menu-one .navbar li img {
		width: 80%;
		height: inherit;
	}
	.banner-info h1 {
		font-size: 40px;
	}
	.section-title h1 {
		font-size: 50px;
	}
	.portfolio-info h3 {
		font-size: 22px;
	}
	.profile-info h1 {
		font-size: 46px;
	}
	.sub-content {
		padding-left: 0;
	}

	.banner-info h3 {
		letter-spacing: 15px;
	}
	.achievement li {
		width: 48%;
	}
	.achievement li + li {
	    margin-left: 0;
	}	
	.achievement-info h4 {
		font-size: 14px;
	}
	.skill-section .rating-bar {
		width: 46%;
	}
	.progress-content {
		padding-right: 20px;
	}
	.language-skill .percent {
		width: 100px;
		height: 100px;
		line-height: 100px;
		font-size: 28px;
	}
	.language-skill li + li {
		margin-left: 15px;
	}
	.post-content {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}
	.language-proficiency ul > li + li {
		margin-left: 5px;
		padding-left: 17px;
	}
	.personal-info .address li h5, 
	.portfolio li h5 {
		min-width: 130px;
	}
	.exprience-image {
		height: 230px;
	}
	.section-padding {
		padding-top: 30px;
		padding-bottom: 50px;
	}
	.profile-section .section-padding {
		padding: 50px 0;
	}
	.banner-info {
		bottom: 60px;
	}
	.entry-summary {
		padding: 30px;
	}
	.post-action li {
		max-width: 50%;
	}
	.post-action {
		padding: 30px 20px 25px;
	}
	#navigation.menu-one .navbar-nav {
		padding-bottom: 0;
	}
	#navigation.menu-one .navbar-nav > li ul li a {
		padding-left: 5px;
		padding-right: 5px;
	}
	#navigation.menu-one .navbar-nav > li span {
		left: 60px;
	}
	#navigation.menu-one .navbar-nav > li i {
		font-size: 25px;
		left: -40px;
	}
	#navigation.menu-one .navbar-nav > li ul.social  i{
		font-size: 14px;
	}
	#navigation.menu-one .navbar-nav > li {
		height: 6.5%;
	}
	#navigation.menu-one .navbar-nav > li .social {
		left: 20px;
	}
	#navigation.menu-two .navbar-nav > li a {
		padding: 15px 20px;
	}	
	#navigation.menu-two .navbar-nav > li.current a, 
	#navigation.menu-two .navbar-nav > li a:hover {
		padding-bottom: 15px;
	}
	.home-two .banner-info h1 {
		font-size: 50px;
	}
	#navigation .navbar:before {
		width: 200%;
	}
	#navigation.menu-one .navbar {
		width: 100%;
	}
	#navigation.menu-one .navbar:before {
	    left: -100%;
	    right: 0;
	}
	.home-one .navbar-nav {
		margin-top: 10px;
	}
	.home-two .section-title h1 {
		font-size: 42px;
	}
}


/* xs */
@media (max-width: 767px) {
	.home-two .section-title h1 {
		font-size: 42px;
	}
	#navigation .navbar:before {
		width: 200%;
	}
	.navbar-toggle  {
	 	margin-top:8px;
	}
	#home-banner{
		background-color: #fff;
	}
	.banner-info {
		bottom: 30px;
	}
	.banner-info h1 {
		font-size: 30px;
	}
	.banner-info h2 {
		font-size: 30px;
		line-height: 25px;
	}
	.banner-info h3 {
		font-size: 14px;
		letter-spacing: 15px;
	}
	.section-padding {
	    overflow: hidden;
	    padding-bottom: 30px;
	    padding-top: 70px;
	}
	.about-section .section-title,
	.section-title {
		margin-bottom: 20px;
	}
	.section-title h1 {
		font-size: 36px;
	}
	.about-info .signature h1 {
		margin-bottom: 10px;
	}
	.about-section address {
		line-height: 24px;
	}
	.achievement li+li {
		margin-left: 0;
	}
	.achievement li {
		float: none;
		display: inline-block;
		margin-bottom: 20px;
		width: 49%;
	}
	.achievement-info h4 {
		font-size: 14px;
	}
	.achievement-info .counter {
		font-size: 40px;
		line-height: 42px;
	}
	.skill-section .rating-bar {
		width: 97%;
	}
	.bar-right {
		float: none;
	}
	.language-skill .percent {
		font-size: 24px;
		width: 80px;
		height: 80px;
		line-height: 80px;
	}
	.language-skill li+li {
		margin-left: 40px;
	}
	.language-skill {
		margin-top: 0;
	}
	.exprience-info h3 {
		font-size: 20px;
	}
	.exprience-image {
		min-height: 200px;
	}
	.exprience-info h5 {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.education-info h3 {
		margin-top: 10px;
		font-size: 20px;
	}
	.portfolio-item {
		margin-bottom: 15px;
	}
	.portfolio-item img {
		width: 100%;
	}
	.education-section hr,
	.contact-section hr {
		margin: 0;
	}
	.text-info {
		margin-bottom: 20px;
	}
	.contact-section h3 {
		font-size: 20px;
	}
	.contact-section .icons {
		height: 65px;
	}
	.contact-section li {
		overflow: hidden;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.blog-section .entry-post {
		margin-bottom: 15px;
	}
	.blog-section .entry-post img {
		width: 100%;
	}
	.profile-info h1 {
		font-size: 40px;
	}
	#profile hr,
	.exprience-section hr {
		margin: 0;
	}
	#profile h3 {
		margin-bottom: 15px;
		font-size: 20px;
	}
	.profile-logo {
		margin-right: 20px;
	}
	.profile-section .section-padding {
		padding-bottom: 30px;
		padding-top: 40px;
	}
	.profile-section .profile-info {
		margin-bottom: 0;
	}
	.sub-content {
		padding-left: 45px;
	}
	.profile-section .icons {
		margin-right: 8px;
	}
	.educational-background .icons {
		margin-right: 0;
	}
	.work-history .history h6 {
		margin-bottom: 10px;
	}
	#profile .educational-background h3, 
	#profile .work-history h3 {
		margin-bottom: 5px;
	}
	.personal-info .address li h5, 
	.portfolio li h5 {
		min-width: 120px;
	}
	.personal-info .address li span, 
	.portfolio li span {
		margin-right: 10px;
	}
	.language-proficiency ul>li {
		width: 49%;
		margin-bottom: 20px;
	}
	.language-proficiency ul>li ul li {
		width: inherit;
		margin-bottom: 0;
	}
	.language-proficiency ul > li + li {
		margin-left: 0;
		border: 0;
		padding-left: 0;
	}
	.btn.btn-primary{
		font-size: 14px;
		padding: 7px 15px;
	}	
	.profile-section .btn.btn-primary {
		margin-top: 15px;
	}
	.declaration h1 {
		margin-top: 20px;
	}
	.expertise .skill-progress,
	.skill-progress,
	.progress {
		margin-bottom: 20px;
	}
	.home-one #navigation .navbar,
	.home-two #navigation .navbar {
		width: 100%;
	}
	.navbar-collapse {
		border-top: 0;
	}
	#blog-details .post-content h2 {
		font-size: 26px;
		margin-bottom: 10px;
	}
	.entry-summary {
		padding: 20px;
	}
	.entry-summary p {
		margin-bottom: 10px;
	}
	.post-action li {
		max-width: 50%;
	}
	.post-action {
		padding: 30px 20px 25px;
	}
	.blog-social {
	    margin: 20px 0;
	}
	#navigation.menu-one .navbar-nav > li i {
		display: none;
	}
	#navigation.menu-one .navbar-nav > li ul li a:hover i,
	#navigation.menu-one .navbar-nav > li ul li i {
		display: block;
		font-size: 14px;
	}
	#navigation.menu-one .navbar-nav > li {
		height: 15%;
	}
	#navigation.menu-one .navbar-nav> li {
		margin-left: 0;
		padding-left: 10px;
	}
	#navigation.menu-one .navbar-nav > li:last-child,
	#navigation.menu-one .navbar-nav > li:last-child:hover {
		margin-left: 0;
		padding-left: 0;
	}
	#navigation.menu-one .navbar-nav>li ul li a {
		margin-top: 18%;
	}
	#navigation.menu-one .navbar-nav > li span{
		left: 20px;
	}
	#navigation.menu-one .navbar-nav > li.current span,
	#navigation.menu-one .navbar-nav > li:hover span {
		left: 25px;
	}
	#navigation .navbar-nav {
		margin-top: 0;
	}
	#navigation .navbar-nav>li .social {
		left: 25px;
	}
	.menu-one .container {
		padding: 0;
	}
	.home-one .logo {
		padding-left: 15px;
	}
	#navigation.menu-two .navbar-nav > li a {
		padding: 10px 15px;
		font-size: 13px;
	}
	#navigation.menu-two .navbar-nav > li.current a, 
	#navigation.menu-two .navbar-nav > li a:hover,
	#navigation.menu-two .navbar-nav > li:last-child a:hover, 
	#navigation.menu-two .navbar-nav > li.current:last-child a {
		padding-bottom: 10px;
	}
	.home-two .banner-info {
		text-align: left;
		width: 100%;
	}
	.logo {
	    background-color: #2d3038;
	    height: 57px;
	    padding: 4px 15px;
	    max-width: 60%;
	}
	.home-one .logo {
		max-width: 100%;
		margin-left: 0;
	}
	.logo img {
		max-width: 75%;
	}
	.home-two .home-social {
		top: 100%;
		margin-top: -40px;
	}
	.home-two .banner-info h1 {
		font-size: 34px;
	    margin-bottom: 5px;
	    margin-top: 43px;
	}
	#navigation .navbar:before, 
	#navigation .navbar:after {
		height: 57px;
	}
	.banner-content .banner-info {
		top: 100%;
		margin-top: -180px;
	}
	.home-banner-one .banner-content {
		padding: 15px;
	}
	.home-banner-one .container {
		max-width: 100%;
		padding: 0;
	}
	.home-one .logo {
		height: 57px;
		padding: 6px 15px 4px;
	}
	.navbar-toggler {
		top: 12px;
	}
	.navbar-collapse {
		margin-top: 55px;
	}
	#navigation.menu-two .logo {
		max-width: 100%;
	}	
	.textwidget img {
		width: 100%;
	}
}

/* XS Portrait */
@media (max-width: 479px) {
	#navigation.menu-two .logo {
		max-width: 75%;
	}

	.banner-info h3 {
		letter-spacing: 8px;
	}
	.achievement {
		text-align: left;
	}
	.section-title h1, .home-two .section-title h1 {
		font-size: 30px;
	}
	.skill-section ul {
		text-align: center;
	}
	.language-skill li+li{
		margin-left: 0;
	}
	.language-skill li {
		float: none;
		display: inline-block;
		width: 49%;
	}
	.exprience-image {
		float: none;
		height: inherit;
		min-height: inherit;
		margin-top: 10px;
	}
	.exprience-info{
		margin-bottom: 20px
	}
	#profile h3 {
		font-size: 19px;
	}
	.sub-content {
		padding-left: 0;
	}
	.personal-info .address li h5, 
	.portfolio li h5 {
		min-width: inherit;
	}
	.personal-info .address li span, 
	.portfolio li span {
		margin-right: 5px;
		padding-left: 10px;
	}
	.personal-info .address li, 
	.portfolio li {
		font-size: 12px;
	}
	.profile-logo {
		float: none;
		margin-right: 0;
		margin-bottom: 20px;
	}
	#blog-details .post-content {
		margin-left: 0;
	}	
	#blog-details .entry-post .time {
		float: none;
		margin-bottom: 10px;
	}
	#blog-details .post-content h2 {
		font-size: 24px;
		line-height: 26px;
	}	
	.post-action li {
		max-width: 100%;
		float: none;
	}
	.post-action .next-post {
		text-align: left;
		margin-top: 15px;
	}
	.blog-social li {
		width: 24%;
	}
	#navigation .navbar-nav > li {
		height: 8%;
	}
	.menu-two .logo {
		padding-bottom: 5px;
	}
	.logo img {
		max-width: 90%;
	}
	.home-two .banner-info {
		padding-left: 15px;
	}
	.home-two .home-social {
		margin-left: 15px;
	}
	.home-one .logo {
		max-width: 80%;
		height: 57px;
	}
	#navigation.menu-one .navbar-nav > li {
		height: 12%;
	}
}

