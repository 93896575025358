// Fonts
@import url(https://fonts.googleapis.com/css?family=Poppins:400,400i,500,500i,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sacramento&display=swap);



// Variables brand
//@import "site/variables";
//@import "site/mixins";

// Site bootstrap variables override
@import "app/variables";
@import "app/notifications";
@import "app/video";

// Bootstrap
@import "bootstrap/bootstrap";


// Font Awesome 5
@import "fontawesomepro/fontawesome.scss";
@import "fontawesomepro/brands.scss";
@import "fontawesomepro/light.scss";
@import "fontawesomepro/regular.scss";
@import "fontawesomepro/solid.scss";

// Animations

@import "../css/animate";

// Plugins
@import "../libs/lightbox-master/dist/ekko-lightbox.min";
@import "../libs/slick-1.8.1/slick/slick";
@import "../libs/slick-1.8.1/slick/slick-theme";
//@import "../libs/aos-master/dist/aos";



@import "site/site";
@import "site/components/lightbox";


